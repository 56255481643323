import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Layout from "../components/layout/Layout";
import { ReactComponent as GridIcon } from '../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Cutouts } from '../assets/icons/cutouts.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import AssetFilter from "../components/layout/AssetFilter";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import CutoutDetails from "../components/cutouts/CutoutsDetail";
import { CartContext, getRenderTemplates } from "../contexts/CartContext";
import { useDebouncedCallback } from "use-debounce";
import "../components/layout/elements/Lists.scss";
import "./CutoutsPage.scss";
import { Link } from "react-router-dom";
import { CutoutAngle, Filter } from "../types/types";
import { useCutoutServiceGetCutoutFilter, useCutoutServiceGetCutoutKey, useTemplateServiceGetTemplate } from "../openapi/queries";
import { BASE_PATH } from "..";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { CombinedResultDTO, CutoutService } from "../openapi/requests";
import { useInView } from "react-intersection-observer";
import { GenerateODataFilter } from "../helpers/odataFunctions";
import { ConfigContext } from "../contexts/ConfigContext";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";
import CreateCutoutProject from "../components/project/CreateCutoutProject";
import { BatchCutout } from "../components/cutouts/batchcutout/BatchCutout";


export const genericAnglesIds = ["c_01c", "c_02c", "c_05c", "c_36c"];
export const templateTypeIds = ["c_01s"];

export const CutoutsPage: React.FC = () => {

    const { odataAssetFilters } = useContext(ConfigContext);    
    const { hasPermission } = useContext(AuthContext);
    const { working, setCurrentProject } = useContext(CartContext);
    const [selectedAssets, setSelectedAssets] = useState<CombinedResultDTO[]>([])
    const [selectedAngles, setSelectedAngles] = useState<CutoutAngle[]>([])
    const [mode, setMode] = useState<"grid" | "list">("grid");
    const [filters, setFilters] = useState<Filter[]>([]);
    const [projectOpen, setProjectOpen] = useState(false);
    const [cutoutsOpen, setCutoutsOpen] = useState(false);
    const [assetInfoOpen, setAssetInfoOpen] = useState(false);    
    const [showProgress, setShowProgress] = useState(false);
    const [batchcutoutOpen, setBatchCutoutOpen] = useState(false);
    const [currentAsset, setCurrentAsset] = useState<CombinedResultDTO | undefined>()
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasPermission("FinalRender")) {
            navigate("/")
        }
    })

    const { ref, inView } = useInView();

    const queryClient = useQueryClient();

    const headerRef = useRef<HTMLDivElement>({} as HTMLDivElement)

    const { data: templates, isFetched: templatesFetched } = useTemplateServiceGetTemplate({ expand: 'scene' });

    const templatesCutouts = useMemo(() => {
        if (templates) {           
            return templates.value.filter(e => e.editorModule.toLowerCase() === "cutout" || e.templatetype.internalName === "3dsmax_360spin");
        }
        return [];
    }, [templates])

    const { data: brandMetadata } = useCutoutServiceGetCutoutFilter({ name: "brand", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: rangeMetadata } = useCutoutServiceGetCutoutFilter({ name: "product_range", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: productTypeMetadata } = useCutoutServiceGetCutoutFilter({ name: "product_type_primary", filter: GenerateODataFilter(odataAssetFilters) });
    const { data: categoryMetadata } = useCutoutServiceGetCutoutFilter({ name: "category", filter: GenerateODataFilter(odataAssetFilters) });

    const brands = useMemo(() => {
        return brandMetadata ?? [];
    }, [brandMetadata]);

    const ranges = useMemo(() => {
        return rangeMetadata ?? [];
    }, [rangeMetadata]);

    const productTypes = useMemo(() => {
        return productTypeMetadata ?? [];
    }, [productTypeMetadata]);

    const categories = useMemo(() => {
        return categoryMetadata ?? [];
    }, [categoryMetadata]);

    const {
        data: cutoutassets,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetched
    } = useInfiniteQuery({
        queryKey: [useCutoutServiceGetCutoutKey, filters, odataAssetFilters],
        initialPageParam: "0",

        queryFn: async ({ pageParam = "0" }) => {
            let combinedFilters = [...filters, ...odataAssetFilters];
            return await CutoutService.getCutout(undefined, undefined, pageParam, GenerateODataFilter(combinedFilters), undefined, undefined, undefined, 'true')
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage["@odata.nextLink"]) {
                let url = new URL(lastPage["@odata.nextLink"]);
                return url.searchParams.get("$skip")
            }
            return null;
        }
    });

    useEffect(() => {
        queryClient.resetQueries({ queryKey: [useCutoutServiceGetCutoutKey], stale: false })
    }, [filters, queryClient, odataAssetFilters])

    const filteredCutouts = useMemo(() => {
        return cutoutassets?.pages.map(x => x.value).flat() ?? []
    }, [cutoutassets])

    useEffect(() => {
        if (inView) {
            !isFetching && fetchNextPage()
        }
    }, [inView, fetchNextPage, isFetching])

    const formatTitle = (title: string) => {

        let spaces = title.split("_");
        let formattedTitle = spaces.reduce((prevValue, currentValue, i, arr) => {
            prevValue += ((i === 0) ? "" : " ") + currentValue[0].toUpperCase() + currentValue.substring(1, currentValue.length);
            return prevValue;
        }, "");

        return formattedTitle;
    }

    const onCreate = () => {
        setProjectOpen(false)
        setCutoutsOpen(true)
    }

    const onClose = () => {
        setSelectedAngles([])
        setCutoutsOpen(false)
    }

    const onCloseBatchCutout = () => {
        setCurrentProject(undefined); 
        setBatchCutoutOpen(false);        
    }

    const onSubmitCutout = () => {
       // setSelectedAngles([])
        setSelectedAngles([])
        setCutoutsOpen(false)
        setBatchCutoutOpen(false)  
        setShowProgress(true)
    }   

    const removeAngles = useCallback((assets: CombinedResultDTO[]) => {

        let availableTemplates: number[] = [];
        let updateSelectedAngles: CutoutAngle[] = [...selectedAngles];

        assets.forEach(asset => {
            const renderTemplates = getRenderTemplates(templatesCutouts, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {

                        const assetTemplate = availableTemplates.find(x => x === renderTemplate?.id);
                        if (!assetTemplate) {
                            availableTemplates.push(renderTemplate.id);
                        }
                    }

                }
            }
        });

        updateSelectedAngles = updateSelectedAngles.filter(x => availableTemplates.includes(x.template.id));
        setSelectedAngles(updateSelectedAngles);

    }, [selectedAngles, templatesCutouts])

    const addAngles = useCallback((assets: CombinedResultDTO[]) => {

        let missingAngles: CutoutAngle[] = [];
        assets.forEach(asset => {

            const renderTemplates = getRenderTemplates(templatesCutouts, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {

                        renderTemplate?.scene.cameras.forEach(cam => {

                            if (genericAnglesIds.includes(cam.cameraName.toLowerCase()) && selectedAngles.map(x => x.id).includes(cam.cameraName.toLowerCase())) {
                                if (!selectedAngles.map(x => x.uniqueId).includes(cam.cameraName.toLowerCase() + "|" + renderTemplate?.id)) {
                                    let missingAngle = { uniqueId: cam.cameraName.toLowerCase() + "|" + renderTemplate?.id, id: cam.cameraName.toLowerCase(), name: cam.label, template: renderTemplate } as CutoutAngle;
                                    missingAngles.push(missingAngle);
                                }
                            }
                        });
                    }
                }
            }
        });

        setSelectedAngles([...selectedAngles, ...missingAngles]);

    }, [selectedAngles, templatesCutouts])

    const onDelete = useCallback((asset: CombinedResultDTO) => {
        let filteredAssets = [...selectedAssets].filter(x => x.id !== asset.id);
        removeAngles(filteredAssets);
        setSelectedAssets(filteredAssets);
     
        if (filteredAssets.length === 0) {
            setSelectedAngles([])
            setCutoutsOpen(false)         
        }        

    },[removeAngles, selectedAssets]);

    const onAssetSelect = (asset: CombinedResultDTO) => {
        ReactGA.event('click', {item: 'asset', location: 'cutouts'});

        let filteredSelectedAssets = [...selectedAssets];

        let index = filteredSelectedAssets.indexOf(asset);
        if (index !== -1) {
            filteredSelectedAssets.splice(index, 1);
            removeAngles(filteredSelectedAssets);
        } else {
            filteredSelectedAssets.push(asset);
            addAngles(filteredSelectedAssets);
        }
        
        setSelectedAssets(filteredSelectedAssets);
    }

    const onAngleChange = (selectedAngles: CutoutAngle[]) => {
        setSelectedAngles(selectedAngles);
    }

    const onAssetDoubleClick = (asset: CombinedResultDTO) => {
        onAssetSelect(asset);
        setProjectOpen(true);       
    }

    const onOpenInfo = (asset: CombinedResultDTO) => {
        setCurrentAsset(asset);
        setAssetInfoOpen(true);
        ReactGA.event('open_dialog', {dialog: 'assetinfo'});
    }
    const debounceOpenInfo = useDebouncedCallback(onOpenInfo, 250);

    const onCloseInfo = (e: any) => {
        debounceOpenInfo.cancel()
    }

    const onScroll = (ref: MutableRefObject<HTMLDivElement>) => {

        const elemPosition = headerRef.current.getBoundingClientRect();
        if (ref.current.scrollTop >= elemPosition.bottom) {
            headerRef.current.classList.add("fixed")
        } else {
            headerRef.current.classList.remove("fixed")
        }
    }

    return (
        <>          
            <Layout className="cutouts-page" onScroll={(ref) => onScroll(ref)}>
                <header>
                    <div>
                        <button className={(mode === "grid") ? 'selected' : 'deselected'} onClick={() => setMode("grid")}>
                            <GridIcon />
                        </button>
                        <button className={(mode === "list") ? 'selected' : 'deselected'} onClick={() => setMode("list")}>
                            <ListIcon />
                        </button>
                    </div>
                </header>

                <div className="cutouts-header">
                    <h1>Cutouts</h1>
                </div>

                <div className="cutouts-content">
                {hasPermission("FinalRender") ? 
                    <div>
                        <div className="cutouts-content-header" ref={headerRef}>

                            <AssetFilter filters={filters} onChange={e => setFilters(e)} isOpen={true} items={filteredCutouts} properties={[
                                { name: 'Search', property: 'name,title,metadata.ean', type: 'search' },
                                { name: 'Brand', property: 'metadata.brand', type: 'select', itemsOverride: brands },
                                { name: 'Range', property: 'metadata.product_range', type: 'select', itemsOverride: ranges },
                                { name: 'Type', property: 'metadata.product_type_primary', type: 'select', itemsOverride: productTypes },
                                { name: 'Category', property: 'metadata.category', type: 'select', itemsOverride: categories },
                            ]} />

                            <div className="cutout-content-actions">
                                <button className="batchcutout-btn" disabled={!templatesFetched} onClick={() => setBatchCutoutOpen(true)}>Batch Cutouts</button>
                                {selectedAssets.length > 0 &&
                                    <>
                                        <button className="clear" onClick={() => setSelectedAssets([])}>
                                            <span>Clear selection</span>
                                        </button>
                                        <button onClick={() => setProjectOpen(true)}>
                                            <span>Create Cut-outs ({selectedAssets.length})</span>
                                            <Cutouts />
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={'cutout-assets-list ' + mode}>
                            <CutoutList assets={filteredCutouts} selectedAssets={selectedAssets} onSelect={onAssetSelect} onAssetDoubleClick={onAssetDoubleClick} onMouseOver={debounceOpenInfo} onMouseOut={onCloseInfo} />
                            {!isFetched || hasNextPage ? <div ref={ref}>Fetching...</div> : null}
                        </div>
                    </div>
                    : <div>You do not have the rights to create cutouts...</div> }
                </div>              

                {showProgress && <div className='rendering-blocker'>
                    <div>
                        <h1>In Progress</h1>
                        <Link className='button primary' to={'/create/projects'}>GO TO PROJECT OVERVIEW</Link>
                    </div>
                </div>}

                <CreateCutoutProject isOpen={projectOpen} onCancel={() => setProjectOpen(false)} onCreate={onCreate}/>         

                <Drawer className='cutouts-detail-overlay' open={cutoutsOpen} onClose={() => setCutoutsOpen(false)} direction='bottom' size="100%" overlayOpacity={0}>
                    <div className="cutouts-detail-overlay-header">
                        <button onClick={onClose}>
                            <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                                <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="cutouts-detail-overlay-content">
                        <CutoutDetails templates={templates?.value ?? []} selectedAssets={selectedAssets} selectedAngles={selectedAngles} onAngleChange={onAngleChange} onDelete={onDelete} onSubmit={onSubmitCutout} />
                    </div>
                </Drawer>

                <Drawer open={assetInfoOpen} onClose={() => setAssetInfoOpen(false)} direction="right" size="400px" overlayOpacity={0}>
                    <div className="info-list cutouts-asset-info">
                        <div className="cutouts-asset-info-close" onClick={() => setAssetInfoOpen(false)}>
                            <span><Close /></span>
                        </div>
                        <div className="info-list-header cutouts-asset-info-header">
                            <span>Metadata</span>
                        </div>
                        <div className="cutouts-asset-info-content">
                            <ul>
                                <li className="cutouts-asset-info-image">
                                    <LazyLoadImage src={BASE_PATH + currentAsset?.thumbnail} threshold={0} />
                                    <span>
                                        {currentAsset?.title}
                                    </span>
                                </li>
                                {currentAsset?.metadata?.map(x =>
                                    <li>
                                        <span>{formatTitle(x.name)}</span>
                                        <span>{x.value}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Drawer>

                <Drawer className='cutouts-detail-overlay' open={batchcutoutOpen} onClose={onCloseBatchCutout} direction='bottom' size="100%" overlayOpacity={0}>
                <div className="cutouts-detail-overlay-header">
                        <button onClick={onCloseBatchCutout}>
                            <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                                <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>                  
                   {batchcutoutOpen && <BatchCutout templates={templates?.value ?? []} isOpen={batchcutoutOpen} onComplete={onSubmitCutout} />}
                </Drawer>  

            </Layout>          
        </>
    )
}

export const CutoutList: React.FC<{ assets: CombinedResultDTO[], selectedAssets: CombinedResultDTO[], onSelect: (asset: CombinedResultDTO) => void, onAssetDoubleClick: (asset: CombinedResultDTO) => void, onMouseOver: (asset: CombinedResultDTO) => void, onMouseOut: (e: any) => void }> = ({ assets, selectedAssets, onSelect, onAssetDoubleClick, onMouseOver, onMouseOut }) => {
    return (
        <ul>
            {assets.map(asset =>
                <li key={asset.id} className={`cutouts-assets-list-item ${selectedAssets.includes(asset) ? "selected" : ""}`} onClick={() => onSelect(asset)} onDoubleClick={() => onAssetDoubleClick(asset)}>
                    <div className="asset-image">
                        <span className="check">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M3.5 8.5L6 11L12.5 4.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        {(asset.isEnabled === false) && <span className='warning'>Not enabled for customers!</span>}
                        <LazyLoadImage src={BASE_PATH + asset.thumbnail} threshold={0} />
                    </div>
                    <div className="asset-header">
                        <div className='title'>
                            <span title={asset.title}>{asset.title}</span>
                            <button><Info onMouseOver={() => onMouseOver(asset)} onMouseOut={onMouseOut} /></button>
                        </div>
                        <span className="ean">{asset.metadata?.find(x => x.name === "ean")?.value || ""}</span>
                    </div>
                </li>
            )}
        </ul>
    )
}

export default CutoutsPage;