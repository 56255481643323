import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AnimationConfiguration, CameraVariant, ModelPlacement, SingleValueCustomization, StillImageConfiguration } from "../models/StillImageConfiguration";
import { useDeliveryConfigurationServiceGetDeliveryConfiguration, useProjectServiceGetProjectKey, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan, useTemplateServiceGetTemplate, useUserServiceGetUserRenderlimits } from "../openapi/queries";
import { CombinedResultDTO, DeliveryConfigurationDTO, MaterialViewDTO, ModelPackViewDTO, ModelViewDTO, ModifierViewDTO, OrderDTO, OrderlineEditDTO, OrderlineService, OrderPostProcessingFeatureDTO, OrderService, ProjectDTO, ProjectService, StillImageSubmissionPlanDTO, TemplateViewDTO, TemplateService, SceneViewDTO, OrderlineViewDTO } from "../openapi/requests";
import { ConfigContext } from "./ConfigContext";
import { GenerateODataFilter } from "../helpers/odataFunctions";
import { OrderStatus, ProjectStatus } from "../models/enums";
import { AuthContext } from "./AuthContext";
import ReactGA from "react-ga4";

//export type OrderStatusLabel = 'draft' | 'rendering preview' | 'rendering final' | 'preview complete' | 'final complete' | 'failed';

export type CombinedAsset = ModelViewDTO | MaterialViewDTO | ModifierViewDTO | ModelPackViewDTO;


export interface OrderConfiguration {
    template: TemplateViewDTO;
    configuration: StillImageConfiguration;
}

export interface Order {
    order: OrderDTO;
    requestManualProcess: boolean;
    orderlinePostProcesses: OrderPostProcessingFeatureDTO[];
    isRendering: boolean;
    status: ProjectStatus;
    configurations: OrderConfiguration[];
};

export interface TemplateMap {
    primarytypes: string[],
    secondarytypes: string[],
    template: string,
    spinTemplate: string
}

export const templateMappings: TemplateMap[] = [
    {
        primarytypes: ["Sink", "Hob", "Bath mat", "Shower tray"],
        secondarytypes: [],
        template: "CoD_CutOut_Sink",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Tap", "Showers"],
        secondarytypes: [],
        template: "CoD_CutOut_Taps",
        spinTemplate: "CoD_CutOut_Taps_Spin360"
    },
    {
        primarytypes: ["Vanity unit", "Wall cabinet", "Basin"],
        secondarytypes: [],
        template: "CoD_CutOut_Bathroom-Furniture",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Fronts", "front"],
        secondarytypes: [],
        template: "CoD_CutOut_Front",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Armchair", "barstool", "Bench", "Chair", "Coffee table", "Dining table", "Table", "Wall light", "Bathroom wall light", "Hood", "Post light", "Shower screen", "Coffee set", "Sofa", "Sun lounger", "Cabinet"],
        secondarytypes: [],
        template: "CoD_CutOut_Furniture",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Oven", "Socket"],
        secondarytypes: [],
        template: "CoD_CutOut_Furniture",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Tumbler", "Soap dispenser", "Soap dish"],
        secondarytypes: [],
        template: "CoD_CutOut_Bathroom-Accessories",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Lamp"],
        secondarytypes: [],
        template: "CoD_CutOut_Lamp-Ceiling",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Handle", "Knob", "Hook"],
        secondarytypes: [],
        template: "CoD_CutOut_HandleKnob",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Mirror"],
        secondarytypes: [],
        template: "CoD_CutOut_Mirror",
        spinTemplate: "CoD_CutOut_SpinTest"
    },
    {
        primarytypes: ["Radiator", "Towel radiator"],
        secondarytypes: [],
        template: "CoD_CutOut_Radiator",
        spinTemplate: "CoD_CutOut_Radiator_Spin360"
    }
];

export const getRenderTemplates = (templates: TemplateViewDTO[], asset: CombinedResultDTO) => {

    let primaryType = asset.metadata?.find(x => x.name === "product_type_primary")?.value;
    let secondaryType = asset.metadata?.find(x => x.name === "product_type_secondary")?.value;

    let filteredTemplateMappings = templateMappings.filter(x =>
        x.primarytypes.some(x => x.toLowerCase() === primaryType?.toLowerCase()) ||
        x.secondarytypes.some(x => x.toLowerCase() === secondaryType!.toLowerCase())
    );

    if (filteredTemplateMappings.length > 0) {
        let filteredTemplateMap = filteredTemplateMappings[0];

        let cutoutTemplate = templates.find(x => x.title.toLowerCase() === filteredTemplateMap.template.toLowerCase());
        let spinTemplate = templates.find(x => x.title.toLowerCase() === filteredTemplateMap.spinTemplate.toLowerCase());

        return [cutoutTemplate, spinTemplate];
    }

};

export interface CartContextState {
    initializing: boolean;
    working: boolean;
    submitting: boolean;

    isLimitExceeded: boolean;
    setLimitExceeded: (value: boolean) => void;

    currentProject: ProjectDTO | undefined;
    setCurrentProject: (project: ProjectDTO | undefined) => Promise<void>;
    createNewProject: (name: string, reference: string | undefined, folder: number | undefined, order: Partial<Order>) => Promise<ProjectDTO>;
    createNewCutoutProject: (name: string, reference: string | undefined, folder: number | undefined, order: Partial<Order>) => Promise<ProjectDTO>;

    currentOrder: Order | undefined;
    updateCurrentOrder: (fieldsToUpdate: Partial<Order>) => Promise<void>;
    updateCutoutCurrentOrder: (fieldsToUpdate: Partial<Order>) => Promise<void>;
    newestDelivery: OrderlineViewDTO[];

    delivery: DeliveryConfigurationDTO;
    setDelivery: (delivery: DeliveryConfigurationDTO) => void;

    orderlineCount: number;
    submitRendering: (plan: StillImageSubmissionPlanDTO) => Promise<void>;
    submitPreview: (plan: StillImageSubmissionPlanDTO, limitImageCount: boolean) => Promise<void>;
    saveRendering: (plan: StillImageSubmissionPlanDTO) => Promise<void>;
}

const contextDefaultValues: CartContextState = {
    currentOrder: undefined,
    currentProject: undefined,
    initializing: false,
    working: false,
    submitting: false,
    isLimitExceeded: false,
    setLimitExceeded: () => { },
    setCurrentProject: () => Promise.resolve(),
    createNewProject: () => Promise.resolve({} as ProjectDTO),
    createNewCutoutProject: () => Promise.resolve({} as ProjectDTO),
    submitPreview: () => Promise.resolve(),
    submitRendering: () => Promise.resolve(),
    saveRendering: () => Promise.resolve(),
    updateCurrentOrder: () => Promise.resolve(),
    updateCutoutCurrentOrder: () => Promise.resolve(),
    delivery: { clientId: 0, id: 0, isDefault: false, title: '' },
    setDelivery: () => { },
    orderlineCount: 0,
    newestDelivery: [],
};

export const CartContext = createContext<CartContextState>(
    contextDefaultValues
);

const emptyOrder: Order = {
    isRendering: false,
    status: ProjectStatus.Draft,
    order: {
        id: 0,
        clientId: 0,
        deliveryconfigurationId: 0,
        notificationRecipients: '',
        orderedByUserId: 0,
        orderReference: '',
        status: OrderStatus.Draft,
        timestampCreate: '',
        projectId: 0,
        timestampDelivery: ''
    },
    configurations: [],
    orderlinePostProcesses: [],
    requestManualProcess: false,
}

export const CartProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { odataFilters, currentClient, userClient } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const queryClient = useQueryClient()
    const [working, setWorking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isLimitExceeded, internalLimitExceeded] = useState(false);
    const [currentProject, internalSetCurrentProject] = useState<ProjectDTO | undefined>(undefined);
    const [orders, setOrders] = useState<OrderDTO[]>([]);
    const [currentOrder, internalSetCurrentOrder] = useState<Order | undefined>(undefined);
    const [delivery, setDelivery] = useState<DeliveryConfigurationDTO>(contextDefaultValues.delivery);
    const [newestDelivery, setNewestDelivery] = useState<OrderlineViewDTO[]>([]);

    const { data: stillImageSubmissionPlans, isLoading: stillImageSubmissionPlansLoading } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({ filter: GenerateODataFilter([{ name: 'client', property: 'clientid', values: [userClient.id], type: 'select' }]) }, undefined, { enabled: userClient.id !== 0 });
    const { data: deliveryConfigurations, isLoading: deliveryConfigurationsLoading } = useDeliveryConfigurationServiceGetDeliveryConfiguration({ filter: GenerateODataFilter(odataFilters) });
    const { data: assemblyTemplates, isLoading: templatesLoading } = useTemplateServiceGetTemplate({ filter: GenerateODataFilter([...odataFilters, { name: 'editor', type: 'select', property: 'editorModule', values: ['clip_assembly'] }]) });

    const { data: userRenderLimits } = useUserServiceGetUserRenderlimits(undefined, { enabled: userClient.id !== 0 })



    useEffect(() => {
        if (deliveryConfigurations && deliveryConfigurations.value && deliveryConfigurations.value.length) {
            setDelivery(deliveryConfigurations.value[0]);
        }
    }, [deliveryConfigurations]);

    useEffect(() => {
        internalSetCurrentProject(undefined);
        setOrders([]);
        internalSetCurrentOrder(undefined);
    }, [currentClient]);

    const orderlineCount = useMemo(() => {
        let count = 0;

        if (currentOrder) {
            currentOrder.configurations.forEach(orderConfig => {
                if (orderConfig.configuration.Cameras) {
                    orderConfig.configuration.Cameras.forEach(cam => {
                        const camera = orderConfig.template.scene.cameras.find(e => e.cameraName === cam.Name);

                        if (camera) {
                            let configurationSpec: StillImageConfiguration = { ...orderConfig.configuration, camera: cam.Name }

                            cam.Variants.forEach((variant) => {
                                const combinations = GetAllConfigurations(configurationSpec, variant, orderConfig.template.scene);
                                count += combinations.length;
                            })
                        }
                    })
                }
            });
        }

        return count;
    }, [currentOrder])

    const setLimitExceeded = useCallback((value: boolean) => {
        internalLimitExceeded(value)
    }, [])

    const setCurrentProject = useCallback(async (project: ProjectDTO | undefined) => {

        if (!project) {
            internalSetCurrentProject(undefined);
            setOrders([]);
            internalSetCurrentOrder(undefined);
            return;
        }          

        if (project.clientId !== currentClient.id) {
            return;
        }

        setWorking(true);
        internalSetCurrentProject(undefined);
        setOrders([]);
        internalSetCurrentOrder(undefined);

        const orders = await ProjectService.getProjectOrders(project.id);

        const sortedOrders = orders.sort((a, b) => {
            return new Date(a.timestampCreate).getTime() - new Date(b.timestampCreate).getTime();
        });

        if (sortedOrders.length) {

            const latestDeliveredOrder = sortedOrders.findLast(e => e.status === OrderStatus.Delivered);

            if (latestDeliveredOrder) {
                setNewestDelivery(await OrderlineService.getOrderOrderline(latestDeliveredOrder.id));
            } else {
                setNewestDelivery([]);
            }

            const lastOrder = sortedOrders[sortedOrders.length - 1];
            const lines = await OrderlineService.getOrderOrderline(lastOrder.id);

            let status: ProjectStatus = ProjectStatus.Draft;

            switch (lastOrder.status) {
                case OrderStatus.Failed:
                case OrderStatus.DeliveryFailed:
                    status = ProjectStatus.Failed;
                    break;
                case OrderStatus.Draft:
                    status = ProjectStatus.Draft;
                    break;
                case OrderStatus.Delivered:
                    status = ProjectStatus.FinalComplete;
                    if (lines.length > 0 && lines[0].isPreview) {
                        status = ProjectStatus.PreviewComplete
                    }
                    break;
                default:
                    status = ProjectStatus.RenderingFinal;
                    if (lines.length > 0 && lines[0].isPreview) {
                        status = ProjectStatus.RenderingPreview
                    }
                    break;
            }

            const seenTemplates: number[] = [];
            const orderConfigurations: OrderConfiguration[] = [];
            let postProcessing: OrderPostProcessingFeatureDTO[] = [];

            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];

                if (seenTemplates.includes(line.templateId)) {
                    continue;
                }

                const template = await TemplateService.getTemplate1(line.templateId, undefined, undefined, undefined, undefined, undefined, 'scene');
                const scene = template.scene;
                postProcessing = line.orderlinePostProcesses;

                if (template && scene) {
                    let propset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');
                    seenTemplates.push(template.id);
                    const config = (JSON.parse(line.configurationSpec) as StillImageConfiguration);

                    if (propset && config.Cameras) {
                        config.Cameras = config.Cameras.map(e => ({ ...e, Variants: e.Variants.filter(e => e.Lighting !== undefined) }));
                    }

                    if (!config.Cameras || config.Cameras.length === 0) {
                        let cam = scene.cameras.find(e => e.cameraName === config.camera) || scene.cameras[0];
                        let lighting: string | undefined = undefined;

                        if (propset) {
                            lighting = config.PropsetSelections.find(e => e.Name === propset?.name)?.Value
                        }

                        config.Cameras = [{
                            Name: cam.cameraName,
                            Variants: [{
                                DeliveryFilenameTemplate: line.deliveryFilenameTemplate,
                                Lighting: lighting
                            }]
                        }];
                    }

                    const orderConfiguration: OrderConfiguration = {
                        template: { ...template, scene: scene },
                        configuration: config,
                    }

                    orderConfigurations.push(orderConfiguration);
                }
            }


            internalSetCurrentOrder({
                order: lastOrder,
                orderlinePostProcesses: postProcessing,
                requestManualProcess: false,
                status,
                isRendering: (status === ProjectStatus.RenderingFinal || status === ProjectStatus.RenderingPreview),
                configurations: orderConfigurations
            })
        }

        setOrders(sortedOrders);
        internalSetCurrentProject(project);
        setWorking(false);
    }, [currentClient.id]);

    const createNewProject = useCallback(async (name: string, reference: string | undefined, folder: number | undefined, order: Partial<Order>) => {
        setWorking(true);
        internalSetCurrentProject(undefined);
        internalSetCurrentOrder(undefined);
        setOrders([]);

        const project = await ProjectService.postProject({
            clientId: currentClient.id,
            id: 0,
            name: name,
            reference: reference,
            isOwner: true,
            timestampCreate: '1900-01-01',
            timestampModify: '1900-01-01',
            createdByUserId: 0,
            modifiedByUserId: 0,
            isEnabled: true,
            folderId: folder,
        });

        if (order.configurations !== undefined) {
            for (let i = 0; i < order.configurations.length; i++) {
                const config = order.configurations[i];

                if (config.configuration === undefined) {
                    let defaultConfig = config.template.scene.defaultConfigurationSpec;

                    if (defaultConfig !== undefined && defaultConfig !== null && defaultConfig !== '') {
                        config.configuration = JSON.parse(defaultConfig);
                    }
                }
            }
        }

        const newOrder: Order = {
            ...emptyOrder,
            ...order,
        }


        let newOrderDto = await OrderService.postOrder({
            projectId: project.id,
            clientId: currentClient.id,
            deliveryconfigurationId: delivery.id,
            id: 0,
            status: 0,
            orderedByUserId: 0,
            timestampCreate: '1900-01-01',
            orderReference: name,
            notificationRecipients: ''
        });

        for (let i = 0; i < newOrder.configurations.length; i++) {
            const orderConfiguration = newOrder.configurations[i];

            await OrderlineService.postOrderOrderline(newOrderDto.id, {
                templateId: orderConfiguration.template.id,
                isPreview: false,
                deliveryFilenameTemplate: '',
                stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                configurationSpec: JSON.stringify(orderConfiguration.configuration),
                orderlinePostProcesses: [],
                requestManualProcess: false,
                spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                videoSetting: { codec: '', height: 600, width: 800 },
                armodelSetting: { createGlb: false, createUsdz: false },
            });
        }

        internalSetCurrentOrder(newOrder);
        internalSetCurrentProject(project);
        setWorking(false);

        queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey] });
        // queryClient.invalidateQueries({queryKey: [useProjectServiceGetProject1Key]});

        ReactGA.event('create_project', {
            type: "in-situ",
        });

        return project;
    }, [currentClient, delivery, stillImageSubmissionPlans, queryClient]);

    const createNewCutoutProject = useCallback(async (name: string, reference: string | undefined, folder: number | undefined, order: Partial<Order>) => {
        setWorking(true);
        internalSetCurrentProject(undefined);
        internalSetCurrentOrder(undefined);
        setOrders([]);

        const project = await ProjectService.postProject({
            id: 0,
            clientId: currentClient.id,
            name: name,
            isOwner: true,
            reference: reference,
            timestampCreate: '1900-01-01',
            timestampModify: '1900-01-01',
            createdByUserId: 0,
            modifiedByUserId: 0,
            isEnabled: true,
            folderId: folder
        });

        if (order.configurations !== undefined) {
            for (let i = 0; i < order.configurations.length; i++) {
                const config = order.configurations[i];

                if (config.configuration === undefined) {
                    let defaultConfig = config.template.scene.defaultConfigurationSpec;

                    if (defaultConfig !== undefined && defaultConfig !== null && defaultConfig !== '') {
                        config.configuration = JSON.parse(defaultConfig);
                    }
                }
            }
        }

        const newOrder: Order = {
            ...emptyOrder,
            ...order,
        }

        let newOrderDto = await OrderService.postOrder({
            projectId: project.id,
            clientId: currentClient.id,
            deliveryconfigurationId: delivery.id,
            id: 0,
            status: 0,
            orderedByUserId: 0,
            timestampCreate: '1900-01-01',
            orderReference: name,
            notificationRecipients: ''
        });

        newOrder.order = newOrderDto

        for (let i = 0; i < newOrder.configurations.length; i++) {
            const orderConfiguration = newOrder.configurations[i];

            await OrderlineService.postOrderOrderline(newOrderDto.id, {
                templateId: orderConfiguration.template.id,
                isPreview: false,
                deliveryFilenameTemplate: '',
                stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                configurationSpec: JSON.stringify(orderConfiguration.configuration),
                orderlinePostProcesses: [],
                requestManualProcess: false,
                spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                videoSetting: { codec: '', height: 600, width: 800 },
                armodelSetting: { createGlb: false, createUsdz: false },
            });
        }

        setOrders([newOrder.order]);
        internalSetCurrentOrder(newOrder);
        internalSetCurrentProject(project);
        setWorking(false);

        queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey] });
        // queryClient.invalidateQueries({queryKey: [useProjectServiceGetProject1Key]});

        ReactGA.event('create_project', {
            type: "cutout",
        });

        return project;

    }, [currentClient, queryClient, delivery, stillImageSubmissionPlans])


    const updateCurrentOrder = useCallback(async (fieldsToUpdate: Partial<Order>) => {
        let orderId: number | undefined = undefined;

        if (currentProject === undefined || currentOrder === undefined) {
            return;
        }

        if (orders.length) {
            const lastOrder = orders[orders.length - 1];

            if (lastOrder.status === 0) {
                orderId = lastOrder.id;
            }
        }

        const order: Order = {
            ...emptyOrder,
            ...currentOrder,
            ...fieldsToUpdate,
        }

        if (order.configurations.length === 0) {
            return;
        }

        internalSetCurrentOrder(order);

        if (!orderId) {
            const order = await OrderService.postOrder({
                projectId: currentProject.id,
                clientId: currentClient.id,
                deliveryconfigurationId: delivery.id,
                id: 0,
                status: 0,
                orderedByUserId: 0,
                timestampCreate: '1900-01-01',
                orderReference: currentProject.name,
                notificationRecipients: ''
            });

            orderId = order.id;
            setOrders([...orders, order]);
        }

        const lines = await OrderlineService.getOrderOrderline(orderId);

        for (let i = 0; i < order.configurations.length; i++) {
            let line = lines[i];
            const orderConfiguration = order.configurations[i];

            if (line) {
                await OrderlineService.putOrderOrderline(orderId, line.id, {
                    templateId: orderConfiguration.template.id,
                    isPreview: false,
                    deliveryFilenameTemplate: '',
                    stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                    configurationSpec: JSON.stringify(orderConfiguration.configuration),
                    orderlinePostProcesses: order.orderlinePostProcesses,
                    requestManualProcess: order.requestManualProcess,
                    spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                    videoSetting: { codec: '', height: 600, width: 800 },
                    armodelSetting: { createGlb: false, createUsdz: false },
                });
            } else {
                await OrderlineService.postOrderOrderline(orderId, {
                    templateId: orderConfiguration.template.id,
                    isPreview: false,
                    deliveryFilenameTemplate: '',
                    stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                    configurationSpec: JSON.stringify(orderConfiguration.configuration),
                    orderlinePostProcesses: order.orderlinePostProcesses,
                    requestManualProcess: order.requestManualProcess,
                    spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                    videoSetting: { codec: '', height: 600, width: 800 },
                    armodelSetting: { createGlb: false, createUsdz: false },
                });
            }
        }

        for (let i = currentOrder.configurations.length; i < lines.length; i++) {
            const line = lines[i];
            await OrderlineService.deleteOrderOrderline(orderId, line.id);
        }

    }, [currentClient, currentOrder, currentProject, delivery, orders, stillImageSubmissionPlans]);

    const updateCutoutCurrentOrder = useCallback(async (fieldsToUpdate: Partial<Order>) => {
        let orderId: number | undefined = undefined;

        if (currentProject === undefined || currentOrder === undefined) {
            return;
        }

        if (orders.length) {
            const lastOrder = orders[orders.length - 1];

            if (lastOrder.status === 0) {
                orderId = lastOrder.id;
            }
        }

        const order: Order = {
            ...emptyOrder,
            ...currentOrder,
            ...fieldsToUpdate,
        }

        if (order.configurations.length === 0) {
            return;
        }

        internalSetCurrentOrder(order);

        if (!orderId) {
            const order = await OrderService.postOrder({
                projectId: currentProject.id,
                clientId: currentClient.id,
                deliveryconfigurationId: delivery.id,
                id: 0,
                status: 0,
                orderedByUserId: 0,
                timestampCreate: '1900-01-01',
                orderReference: currentProject.name,
                notificationRecipients: ''
            });

            orderId = order.id;
            setOrders([...orders, order]);
        }

        const lines = await OrderlineService.getOrderOrderline(orderId);

        for (let i = 0; i < order.configurations.length; i++) {

            const orderConfiguration = order.configurations[i];
            let line = lines.find(x => x.templateId === orderConfiguration.template.id);

            if (line) {
                await OrderlineService.putOrderOrderline(orderId, line.id, {
                    templateId: orderConfiguration.template.id,
                    isPreview: false,
                    deliveryFilenameTemplate: '',
                    stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                    configurationSpec: JSON.stringify(orderConfiguration.configuration),
                    orderlinePostProcesses: order.orderlinePostProcesses,
                    requestManualProcess: order.requestManualProcess,
                    spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                    videoSetting: { codec: '', height: 600, width: 800 },
                    armodelSetting: { createGlb: false, createUsdz: false },
                });
            } else {
                await OrderlineService.postOrderOrderline(orderId, {
                    templateId: orderConfiguration.template.id,
                    isPreview: false,
                    deliveryFilenameTemplate: '',
                    stillImageSetting: { backgroundcolor: '#000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: stillImageSubmissionPlans?.value[0].id },
                    configurationSpec: JSON.stringify(orderConfiguration.configuration),
                    orderlinePostProcesses: order.orderlinePostProcesses,
                    requestManualProcess: order.requestManualProcess,
                    spin360Setting: { backgroundcolor: '#000', fileformat: 'jpg', frames: 36, height: 600, width: 800 },
                    videoSetting: { codec: '', height: 600, width: 800 },
                    armodelSetting: { createGlb: false, createUsdz: false },
                });
            }
        }

        // if (lines.length > 1) {
        var deleteLines = currentOrder.configurations.filter(x => !fieldsToUpdate.configurations?.map(f => f.template.id).includes(x.template.id));

        if (deleteLines) {
            for (let i = 0; i < deleteLines.length; i++) {
                const deleteLine = deleteLines[i];
                const line = lines.find(x => x.templateId === deleteLine.template.id)
                if (line) {
                    await OrderlineService.deleteOrderOrderline(orderId, line.id);
                }
            }
        }
        // }



    }, [currentClient, currentOrder, currentProject, delivery, orders, stillImageSubmissionPlans]);

    const submitCart = useCallback(async (isPreview: boolean, plan: StillImageSubmissionPlanDTO, limitImageCount: boolean = false, placeOrder: boolean = true) => {
        if (!currentProject || !currentOrder) {
            return;
        }

        const lastOrder = orders[orders.length - 1];

        const newLines: OrderlineEditDTO[] = [];
        const animationAssemblies: { lines: OrderlineEditDTO[], filename: string, config: AnimationConfiguration }[] = [];

        currentOrder.configurations.forEach(orderConfig => {
            const configTemplateType = orderConfig.template.templatetype;
            const fileformat = (orderConfig.template.editorModule === 'cutout' || configTemplateType.internalName === "3dsmax_360spin") ? 'png' : 'jpg';
            let maxSize = isPreview ? 800 : 4000;
            let propset = orderConfig.template.scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

            const orderlineDefaults: OrderlineEditDTO = {
                templateId: orderConfig.template.id,
                isPreview: isPreview,
                deliveryFilenameTemplate: '',
                stillImageSetting: { backgroundcolor: '000000', fileformat, height: 600, width: 800, stillimagesubmissionplanId: plan.id },
                configurationSpec: '',
                orderlinePostProcesses: currentOrder.orderlinePostProcesses,
                requestManualProcess: currentOrder.requestManualProcess,
                spin360Setting: { backgroundcolor: '000000', fileformat, frames: 36, height: 600, width: 800 },
                videoSetting: { codec: '', height: 600, width: 800 },
                armodelSetting: { createGlb: false, createUsdz: false },
            }

            //remove preview watermark, when rendering final images.
            if (!isPreview) {
                orderlineDefaults.orderlinePostProcesses = orderlineDefaults.orderlinePostProcesses.filter(e => !e.name.toLowerCase().includes("watermark preview"));
            }

            if (orderConfig.configuration.Cameras) {
                orderConfig.configuration.Cameras.forEach(cam => {
                    const camera = orderConfig.template.scene.cameras.find(e => e.cameraName === cam.Name);

                    //Spin 360
                    if (configTemplateType.internalName === "3dsmax_360spin") {
                        let spin360SubmissionPlan = stillImageSubmissionPlans.value.find(x => x.name?.toLowerCase().includes("spin360"))
                        if (spin360SubmissionPlan) {
                            orderlineDefaults.stillImageSetting.stillimagesubmissionplanId = spin360SubmissionPlan?.id;
                            maxSize = 1750;
                        }
                    }

                    if (camera) {
                        if (camera.isAnimated && !hasPermission("RenderAnimatedCamera")) {
                            return;
                        }

                        const ratio = camera.aspectRatio ?? 1;

                        let width = maxSize;
                        let height = Math.round(maxSize / ratio);

                        if (height > maxSize) {
                            width = Math.round(maxSize * ratio);
                            height = maxSize;
                        }

                        let configurationSpec: StillImageConfiguration = { ...orderConfig.configuration, camera: cam.Name }

                        cam.Variants.forEach((variant, i) => {
                            if (limitImageCount && i !== 0) {
                                return;
                            }

                            if (variant.Lighting && propset) {
                                configurationSpec.PropsetSelections = [...configurationSpec.PropsetSelections.filter(e => e.Name !== propset?.name), { Name: propset?.name, Value: variant.Lighting }]
                            }

                            let combinations = GetAllConfigurations(configurationSpec, variant, orderConfig.template.scene);

                            if (limitImageCount) {
                                combinations = [combinations[0]];
                            }

                            combinations.forEach(c => {
                                newLines.push({
                                    ...orderlineDefaults,
                                    configurationSpec: c.config,
                                    stillImageSetting: { ...orderlineDefaults.stillImageSetting, width, height },
                                    deliveryFilenameTemplate: c.filename
                                });
                            });
                        })
                    }
                })
            }

            if (orderConfig.configuration.Animations !== undefined && hasPermission("RenderVideo")) {
                let propset = orderConfig.template.scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

                orderConfig.configuration.Animations.forEach(animation => {
                    animation.Variants.forEach(variant => {
                        const config: {
                            lines: OrderlineEditDTO[],
                            filename: string,
                            config: AnimationConfiguration
                        } = {
                            filename: variant.DeliveryFilenameTemplate,
                            lines: [],
                            config: { Clips: [] }
                        }

                        animation.Shots.forEach(shot => {

                            const camera = orderConfig.template.scene.cameras.find(e => e.cameraName === shot.CameraName);

                            if (camera) {
                                let configurationSpec: StillImageConfiguration = { ...orderConfig.configuration, camera: camera.cameraName }

                                if (variant.Lighting && propset) {
                                    configurationSpec.PropsetSelections = [...configurationSpec.PropsetSelections.filter(e => e.Name !== propset?.name), { Name: propset?.name, Value: variant.Lighting }]
                                }

                                const size = isPreview ? 256 : 1080;

                                const line = {
                                    ...orderlineDefaults,
                                    configurationSpec: JSON.stringify(configurationSpec),
                                    stillImageSetting: { ...orderlineDefaults.stillImageSetting, width: size, height: size },
                                    videoSetting: { ...orderlineDefaults.videoSetting, width: size, height: size },
                                    deliveryFilenameTemplate: '{{ID}}'
                                };

                                newLines.push(line);
                                config.lines.push(line);
                            } else {
                                console.warn("could not find camera");
                            }

                        });

                        animationAssemblies.push(config);
                    });
                });
            }
        });

        if (newLines.length === 0) {
            throw new Error("No orderlines");
        }

        //Validate limits
        let limitExceeded = false;
        stillImageSubmissionPlans.value.filter(x => !x.name?.toLowerCase().includes("cutout")).forEach(submissionPlan => {

            if (limitExceeded) {
                return;
            }

            let userLimit = userRenderLimits.find(x => x.submissionPlan.id === submissionPlan.id);
            let maxLimit = submissionPlan.defaultRenderLimit;
            if (userLimit) {
                maxLimit = userLimit.limit;
            }
            let linecount = newLines.filter(x => x.stillImageSetting.stillimagesubmissionplanId === submissionPlan.id).length;
            limitExceeded = (linecount > maxLimit);
        });

        if (limitExceeded) {
            internalLimitExceeded(limitExceeded);
            return;
        }

        const lines = await OrderlineService.getOrderOrderline(lastOrder.id);
        const orderlines: { line: OrderlineEditDTO, savedLine: OrderlineViewDTO }[] = [];

        setSubmitting(true);

        let linesTotal = newLines.length;
        let linesProcessed = 0;

        for (let i = 0; i < newLines.length; i++) {
            const newLine = newLines[i];

            if (lines[i]) {
                orderlines.push({
                    line: newLine,
                    savedLine: await OrderlineService.putOrderOrderline(lastOrder.id, lines[i].id, newLine)
                });
            } else {
                orderlines.push({
                    line: newLine,
                    savedLine: await OrderlineService.postOrderOrderline(lastOrder.id, newLine)
                });
            }

            linesProcessed = linesProcessed + 1;
            const event = new CustomEvent("loading", { detail: { total: linesTotal, processed: linesProcessed } });
            document.dispatchEvent(event);
        }

        for (let i = newLines.length; i < lines.length; i++) {
            const line = lines[i];
            await OrderlineService.deleteOrderOrderline(lastOrder.id, line.id);

            linesProcessed = linesProcessed + 1;
            const event = new CustomEvent("loading", { detail: { total: linesTotal, processed: linesProcessed } });
            document.dispatchEvent(event);
        }

        if (assemblyTemplates.value.length > 0) {
            const assemblyTemplate = assemblyTemplates.value[0];
            const submissionPlan = stillImageSubmissionPlans.value.find(e => e.name?.includes('Video Frame Assembly'));

            if (submissionPlan) {
                for (let index = 0; index < animationAssemblies.length; index++) {
                    const animationAssembly = animationAssemblies[index];

                    animationAssembly.lines.forEach(line => {
                        const orderline = orderlines.find(e => e.line === line);
                        if (orderline) {
                            animationAssembly.config.Clips.push({ OrderlineID: orderline.savedLine.id });
                        }
                    });

                    await OrderlineService.postOrderOrderline(lastOrder.id, {
                        templateId: assemblyTemplate.id,
                        isPreview: isPreview,
                        deliveryFilenameTemplate: animationAssembly.filename,
                        stillImageSetting: { backgroundcolor: '000000', fileformat: 'png', height: 1080, width: 1080, stillimagesubmissionplanId: submissionPlan.id },
                        configurationSpec: JSON.stringify(animationAssembly.config),
                        orderlinePostProcesses: [],
                        requestManualProcess: currentOrder.requestManualProcess,
                        spin360Setting: { backgroundcolor: '000000', fileformat: 'png', frames: 36, height: 1080, width: 1080 },
                        videoSetting: { codec: '', height: 1080, width: 1080 },
                        armodelSetting: { createGlb: false, createUsdz: false },
                    });

                    linesProcessed = linesProcessed + 1;
                    const event = new CustomEvent("loading", { detail: { total: linesTotal, processed: linesProcessed } });
                    document.dispatchEvent(event);
                }
            }
        }

        setSubmitting(false);

         if (placeOrder) {
             await OrderService.putOrderPlace(lastOrder.id);
         }

        const newOrders = await ProjectService.getProjectOrders(currentProject.id);
        setOrders(newOrders);

        internalSetCurrentOrder({ ...currentOrder, isRendering: true, status: isPreview ? ProjectStatus.RenderingPreview : ProjectStatus.RenderingFinal });

        queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey] });


    }, [currentOrder, currentProject, orders, queryClient, stillImageSubmissionPlans, userRenderLimits, assemblyTemplates, hasPermission]);

    const saveRendering = useCallback(async (plan: StillImageSubmissionPlanDTO) => {
        await submitCart(false, plan, false, false);
    }, [submitCart]);

    const submitRendering = useCallback(async (plan: StillImageSubmissionPlanDTO) => {
        await submitCart(false, plan);
    }, [submitCart]);

    const submitPreview = useCallback(async (plan: StillImageSubmissionPlanDTO, limitImageCount: boolean) => {
        await submitCart(true, plan, limitImageCount);
    }, [submitCart]);

    return (
        <CartContext.Provider
            value={{
                working,
                submitting,
                isLimitExceeded,
                setLimitExceeded,
                initializing: (deliveryConfigurationsLoading || stillImageSubmissionPlansLoading || templatesLoading),
                currentProject,
                setCurrentProject,
                delivery,
                setDelivery,
                currentOrder,
                updateCutoutCurrentOrder,
                updateCurrentOrder,
                createNewProject,
                createNewCutoutProject,
                saveRendering,
                submitRendering,
                submitPreview,
                orderlineCount,
                newestDelivery,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};



const getAllCombinations = (configs: SingleValueCustomization[]) => {
    const divisors: number[] = [];
    let permsCount = 1;

    let arraysToCombine: { value: string, filenamePrefix?: string }[][] = configs.map(c => {
        let uniqueItems = [...new Set(c.Values!.map(e => e.value))];

        return uniqueItems.map(e => ({ value: e, filenamePrefix: c.Values?.find(v => v.value === e)?.filenamePrefix }));
    });

    for (let i = arraysToCombine.length - 1; i >= 0; i--) {
        divisors[i] = divisors[i + 1] ? divisors[i + 1] * arraysToCombine[i + 1].length : 1;
        permsCount *= (arraysToCombine[i].length || 1);
    }

    const getCombination = (n: number, arrays: { value: string, filenamePrefix?: string }[][], divisors: number[]) => arrays.reduce((acc, arr, i) => {
        acc.push(arr[Math.floor(n / divisors[i]) % arr.length]);
        return acc;
    }, []);

    const combinations = [];
    for (let i = 0; i < permsCount; i++) {
        combinations.push(getCombination(i, arraysToCombine, divisors));
    }
    return combinations;
};

const getBatchItems = (config: StillImageConfiguration, scene: SceneViewDTO): SingleValueCustomization[] => {
    const result: SingleValueCustomization[] = [];

    const hasMultipleValues = (value: SingleValueCustomization) => {
        if (value.Values) {
            let uniqueItems = [...new Set(value.Values.map(e => e.value))];
            return uniqueItems.length > 1;
        }
        return false;
    }

    const recurse = (model: ModelPlacement) => {
        for (let i = 0; i < model.SurfaceSelections.length; i++) {
            if (hasMultipleValues(model.SurfaceSelections[i])) {
                result.push(model.SurfaceSelections[i]);
            }
        }
        for (let i = 0; i < model.ModelSelections.length; i++) {
            if (hasMultipleValues(model.ModelSelections[i])) {
                result.push(model.ModelSelections[i]);
            }
            recurse(model.ModelSelections[i]);
        }
    }

    for (let i = 0; i < config.SurfaceSelections.length; i++) {
        if (hasMultipleValues(config.SurfaceSelections[i])) {
            result.push(config.SurfaceSelections[i]);
        }
    }

    for (let i = 0; i < config.ModifierSelections.length; i++) {
        if (hasMultipleValues(config.ModifierSelections[i])) {
            result.push(config.ModifierSelections[i]);
        }
    }

    for (let i = 0; i < config.ModelSelections.length; i++) {
        if (hasMultipleValues(config.ModelSelections[i])) {
            result.push(config.ModelSelections[i]);
        }
        recurse(config.ModelSelections[i]);
    }

    var lighting = scene.propsets.find(e => e.label.toLowerCase() !== 'lighting');

    for (let i = 0; i < config.PropsetSelections.length; i++) {
        if (hasMultipleValues(config.PropsetSelections[i])) {
            if (config.PropsetSelections[i].Name !== lighting?.name) {
                result.push(config.PropsetSelections[i]);
            }
        }
    }

    return result;
}

const GetAllConfigurations = (config: StillImageConfiguration, variant: CameraVariant, scene: SceneViewDTO): { filename: string, config: string }[] => {

    var batchItems = getBatchItems(config, scene);
    var result: { filename: string, config: string }[] = [];

    if (batchItems.length === 0) {
        result.push({ config: JSON.stringify(config), filename: variant.DeliveryFilenameTemplate });
    } else {
        let combinations = getAllCombinations(batchItems);

        combinations.forEach(combination => {
            let filename = variant.DeliveryFilenameTemplate;

            for (let m = 0; m < batchItems.length; m++) {
                batchItems[m].Value = combination[m].value;

                if (combination[m].filenamePrefix) {
                    filename = combination[m].filenamePrefix + "_" + filename;
                }
            }


            let isValid = true;

            const fixValue = (parent: SingleValueCustomization, child: SingleValueCustomization | ModelPlacement) => {
                if (child.Values && child.Values.length > 1) {
                    let value = child.Values.find(e => e.value === child.Value &&
                        e.name !== undefined &&
                        (e.parentName === undefined || e.parentName === parent.Value));

                    if (value) {
                        child.Name = value.name!;
                    } else {
                        if (child.validParents?.includes(parent.Name)) {
                            return false;
                        }
                        if (child.Value !== child.Values[0].value) {
                            return false;
                        }
                    }
                }

                var model = child as ModelPlacement;

                if (model.ModelSelections) {
                    for (let i = 0; i < model.ModelSelections.length; i++) {
                        const element = model.ModelSelections[i];
                        if (!fixValue(child, element)) {
                            return false;
                        }
                    }
                }
                if (model.SurfaceSelections) {
                    for (let i = 0; i < model.SurfaceSelections.length; i++) {
                        const element = model.SurfaceSelections[i];
                        if (!fixValue(child, element)) {
                            return false;
                        }
                    }
                }

                return true;
            }

            for (let i = 0; i < config.ModelSelections.length; i++) {
                const model = config.ModelSelections[i];

                if (model.ModelSelections) {
                    for (let i = 0; i < model.ModelSelections.length; i++) {
                        const element = model.ModelSelections[i];
                        if (!fixValue(model, element)) {
                            isValid = false;
                            break;
                        }
                    }
                }
                if (model.SurfaceSelections) {
                    for (let i = 0; i < model.SurfaceSelections.length; i++) {
                        const element = model.SurfaceSelections[i];
                        if (!fixValue(model, element)) {
                            isValid = false;
                            break;
                        }
                    }
                }
            }

            if (isValid) {
                result.push({ config: JSON.stringify(config), filename });
            }
        })
    }

    return result;
};